import styles from "./MealCategories.module.css";
import { useState, useRef, useEffect } from "react";

const MealCategories = (props) => {
  const [vegValue, setVegValue] = useState(0);
  const [meatValue, setMeatValue] = useState(0);
  const [fishValue, setFishValue] = useState(0);
  const [slideOrder, setSlideOrder] = useState([]);

  useEffect(() => {
    if (
      (props.vegDays || props.meatDays || props.fishDays) &&
      !props.isNewDate
    ) {
      props.vegDays ? setVegValue(Number(props.vegDays)) : setVegValue(0);
      props.meatDays ? setMeatValue(Number(props.meatDays)) : setMeatValue(0);
      props.fishDays ? setFishValue(Number(props.fishDays)) : setFishValue(0);
    } else {
      const diff = props.days - Math.round(props.days / 3) * 3;
      setVegValue(Math.round(props.days / 3 + diff));
      setMeatValue(Math.round(props.days / 3));
      setFishValue(Math.round(props.days / 3));
    }
  }, [
    props.days,
    props.vegDays,
    props.meatDays,
    props.fishDays,
    props.isNewDate,
  ]);

  let vegRef = useRef();
  let meatRef = useRef();
  let fishRef = useRef();

  const clickOrderHandler = (event) => {
    setSlideOrder((oldArray) => [...oldArray, event.target.id]);
  };

  const prosentShare = (foodDays) => {
    if (foodDays === 0) {
      return "0%";
    }
    return `${((foodDays * 100) / props.days).toFixed(0)}%`;
  };

  const sliderHandler = (event) => {
    let vegNewValue = vegValue;
    let fishNewValue = fishValue;

    let meatNewValue = meatValue;
    let lastSlided = slideOrder[slideOrder.length - 1];
    //MEATSLIDER

    if (event.target.id === "meatRange") {
      let goingUp = event.target.value > meatValue;

      let valueChange = Math.ceil(event.target.value - meatValue);

      if (goingUp) {
        if (fishRef.current.value <= 0) {
          fishNewValue = 0;
          vegNewValue = vegValue - valueChange;
        } else if (vegRef.current.value <= 0) {
          vegNewValue = 0;
          fishNewValue = fishValue - valueChange;
        } else if (lastSlided === "fishRange") {
          vegNewValue = vegValue - valueChange;
        } else {
          fishNewValue = fishValue - valueChange;
        }
      } else {
        if (lastSlided === "fishRange") {
          vegNewValue = vegValue + Math.abs(valueChange);
        } else {
          fishNewValue = fishValue + Math.abs(valueChange);
        }
      }

      setFishValue(fishNewValue);
      setVegValue(vegNewValue);

      setMeatValue(Number(event.target.value));
    }

    //FISHSLIDER

    if (event.target.id === "fishRange") {
      let goingUp = event.target.value > fishValue;

      let valueChange = Math.ceil(event.target.value - fishValue);
      console.log(valueChange);

      if (goingUp) {
        if (meatRef.current.value <= 0) {
          meatNewValue = 0;
          vegNewValue = vegValue - valueChange;
        } else if (vegRef.current.value <= 0) {
          vegNewValue = 0;
          meatNewValue = meatValue - valueChange;
        } else if (lastSlided === "vegRange") {
          meatNewValue = meatValue - valueChange;
        } else {
          vegNewValue = vegValue - valueChange;
        }
      } else {
        if (lastSlided === "vegRange") {
          meatNewValue = meatValue + Math.abs(valueChange);
        } else {
          vegNewValue = vegValue + Math.abs(valueChange);
        }
      }

      setMeatValue(meatNewValue);
      setVegValue(vegNewValue);
      setFishValue(Number(event.target.value));
    }

    //VEGSLIDER

    if (event.target.id === "vegRange") {
      let goingUp = event.target.value > vegValue;

      let valueChange = Math.ceil(event.target.value - vegValue);

      if (goingUp) {
        if (fishRef.current.value <= 0) {
          fishNewValue = 0;
          meatNewValue = meatValue - valueChange;
        } else if (meatRef.current.value <= 0) {
          meatNewValue = 0;
          fishNewValue = fishValue - valueChange;
        } else if (lastSlided === "meatRange") {
          fishNewValue = fishValue - valueChange;
        } else {
          meatNewValue = meatValue - valueChange;
        }
      } else {
        if (lastSlided === "meatRange") {
          fishNewValue = fishValue + Math.abs(valueChange);
        } else {
          meatNewValue = meatValue + Math.abs(valueChange);
        }
      }

      setFishValue(fishNewValue);
      setMeatValue(meatNewValue);

      setVegValue(Number(event.target.value));
    }
  };

  return (
    <div>
      <h3>Fordeling av retter</h3>
      <p>{props.days} dager</p>
      <div className={styles.categories}>
        <p></p>
        <div className={styles.mealRange}>
          <input
            ref={vegRef}
            type="range"
            id="vegRange"
            name="vegRange"
            value={vegValue}
            onChange={sliderHandler}
            onClick={clickOrderHandler}
            steps="1"
            min="0"
            max={props.days}
            className={styles.vranger}
            disabled={!props.endDateSet}
          />
          <div className={styles.checker}>
            <label htmlFor="vegetarian">
              Vegetar: {vegValue.toFixed(0)} dager <br></br>
              {prosentShare(vegValue)}
            </label>
          </div>
        </div>

        <div className={styles.mealRange}>
          <input
            type="range"
            ref={meatRef}
            id="meatRange"
            name="meatRange"
            value={meatValue}
            onChange={sliderHandler}
            onClick={clickOrderHandler}
            min="0"
            steps="1"
            max={props.days}
            className={styles.vranger}
            disabled={!props.endDateSet}
          />
          <div className={styles.checker}>
            <label htmlFor="meat">
              Kjøtt: {meatValue.toFixed(0)} dager<br></br>
              {prosentShare(meatValue)}
            </label>
          </div>
        </div>

        <div className={styles.mealRange}>
          <input
            onClick={clickOrderHandler}
            type="range"
            id="fishRange"
            ref={fishRef}
            name="fishRange"
            value={fishValue}
            onChange={sliderHandler}
            min="0"
            steps="1"
            max={props.days}
            className={styles.vranger}
            disabled={!props.endDateSet}
          />
          <div className={styles.checker}>
            <label htmlFor="fish">
              Fisk: {fishValue.toFixed(0)} dager<br></br>
              {prosentShare(fishValue)}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealCategories;
