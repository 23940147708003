import styles from "./Menu.module.css";
import { useState, useEffect } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";
import { GiMeat } from "react-icons/gi";
import { FaFish } from "react-icons/fa";
import { ImLeaf } from "react-icons/im";

const Menu = (props) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [randomMeal, setRandomMeal] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [opacity, setOpacity] = useState("100%");

  const dayOfweek = [
    "Søndag",
    "Mandag",
    "Tirsdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lørdag",
  ];

  useEffect(() => {
    setRandomMeal(props.meal);
  }, [props.meal]);

  const randomMealHandler = () => {
    setHasChanged(true);
    setIsEdited(false);
    setOpacity("100%");
    let randomizedMeal =
      props.longList[Math.floor(Math.random() * props.longList.length)];
    if (randomizedMeal.meal === randomMeal.meal) {
      randomizedMeal =
        props.longList[Math.floor(Math.random() * props.longList.length)];
    }
    props.randomMealListChanger(randomizedMeal, props.counter);
    setRandomMeal(randomizedMeal);
  };
  const changeMealHandler = (event) => {
    event.preventDefault();
    const newMeal = {
      meal: event.target[0].value,
      price: 0,
      childFriendly: 0,
      timeConsuming: 0,
      id: Math.random(0, 1000),
    };
    props.randomMealListChanger(newMeal, props.counter);
    setRandomMeal(newMeal);
    setHasChanged(true);
    setIsEditing(false);
  };
  const editHandler = () => {
    setOpacity("20%");
    setHasChanged(false);
    setIsEdited(true);
    setIsEditing(true);
  };

  const cancelEditHandler = () => {
    setIsEdited(false);
    setIsEditing(false);
    setOpacity("100%");
  };
  const switcharoo = (extra) => {
    if (isEdited) return "0%";
    if (!hasChanged) return `${props[extra]}%`;
    else return `${randomMeal[extra]}%`;
  };

  return (
    <div className={styles.list}>
      {/* {props.dateCounter.getDay() === 1 || props.counter === 0 ? (
        <div className={styles.weekDiv}>
          {" "}
          <p>Uke {props.weekCounter() / 2}</p>
          <br></br>
        </div>
      ) : null} */}
      <ul>
        <li>
          {dayOfweek[props.dateCounter.getDay()]}
          {" - "}
          {`${props.dateCounter.getDate()}.${props.dateCounter.getMonth() + 1}`}
          {isEditing && !props.savedMenu ? (
            <form onSubmit={changeMealHandler}>
              <input placeholder="Endre måltid manuelt"></input>
              <button type="submit">✓</button>
              <button type="button" onClick={cancelEditHandler}>
                X
              </button>
            </form>
          ) : (
            <p
              style={{ fontWeight: "bold" }}
              // contentEditable="true"

              // suppressContentEditableWarning="true"
            >
              {" "}
              {!hasChanged ? props.meal : randomMeal.meal}
            </p>
          )}

          <div className={styles.bars} style={{ opacity: opacity }}>
            Pris
            <div className={styles.border}>
              <div
                className={styles.bar}
                style={{ width: switcharoo("price"), height: "10px" }}
              />
            </div>
            Barnevennlig
            <div className={styles.border}>
              <div
                className={styles.bar}
                style={{
                  width: switcharoo("childFriendly"),
                  height: "10px",
                }}
              />
            </div>
            Kokketid
            <div className={styles.border}>
              <div
                className={styles.bar}
                style={{ width: switcharoo("timeConsuming"), height: "10px" }}
              />
            </div>
          </div>
          {/* <ul id={styles.extras}>
            <li>Pris: {!randomized ? props.price : randomMeal.price}</li>
            <li>
              Barnevennlighet:{" "}
              {!randomized ? props.child : randomMeal.childFriendly}
            </li>
            <li>Tid: {!randomized ? props.time : randomMeal.timeConsuming}</li>
          </ul> */}
          {/* <p className={styles.random} onClick={randomMealHandler}>
            Tilfeldig måltid
          </p> */}
          {!props.savedMenu && (
            <div className={styles.icons}>
              <AutorenewIcon
                className={styles.random}
                onClick={randomMealHandler}
              />
              <EditIcon className={styles.random} onClick={editHandler} />
            </div>
          )}
          {!hasChanged
            ? props.type === "meat" &&
              !isEdited && (
                <GiMeat
                  className={styles.mealIcon}
                  style={{
                    color: "red",
                  }}
                />
              )
            : randomMeal.type === "meat" &&
              !isEdited && (
                <GiMeat
                  className={styles.mealIcon}
                  style={{
                    color: "red",
                  }}
                />
              )}
          {!hasChanged
            ? props.type === "fish" &&
              !isEdited && (
                <FaFish
                  className={styles.mealIcon}
                  style={{
                    color: "blue",
                  }}
                />
              )
            : randomMeal.type === "fish" &&
              !isEdited && (
                <FaFish
                  className={styles.mealIcon}
                  style={{
                    color: "blue",
                  }}
                />
              )}
          {!hasChanged
            ? props.type === "vegetarian" &&
              !isEdited && (
                <ImLeaf
                  className={styles.mealIcon}
                  style={{
                    color: "green",
                  }}
                />
              )
            : randomMeal.type === "vegetarian" &&
              !isEdited && (
                <ImLeaf
                  className={styles.mealIcon}
                  style={{
                    color: "green",
                  }}
                />
              )}
          {/* <FaFish
            className={styles.mealIcon}
            style={{
              color: "blue",
            }}
          />
          <ImLeaf
            className={styles.mealIcon}
            style={{
              color: "green",
            }}
          /> */}
        </li>
      </ul>
    </div>
  );
};

export default Menu;
