import { useState, useEffect } from "react";
import DatePicker from "./DatePicker";
import MealCategories from "./MealCategories";
import MealsExtra from "./MealsExtra";
import styles from "./Form.module.css";
import { Link } from "react-router-dom";
import Modal from "../../ui/Modal.js";

const Form = (props) => {
  const [helperShow, setHelperShow] = useState(false);

  const [days, setDays] = useState(0);
  const [dates, setDates] = useState({});
  const [isNewDate, setisNewDate] = useState(false);
  const modalClicker = () => {
    setHelperShow((prevState) => {
      return !prevState;
    });
  };
  useEffect(() => {
    if (props.daysFromGenerator) {
      setDays(props.daysFromGenerator);
    }
  }, [props.daysFromGenerator]);

  useEffect(() => {
    if (props.startDate && props.endDate) {
      setDates({ startDate: props.startDate, endDate: props.endDate });
    }
  }, [props.startDate, props.endDate]);

  const dateHandler = (dateobject) => {
    setDates({
      startDate: dateobject.startDate,
      endDate: dateobject.endDate,
    });
    if (dateobject.startDate && dateobject.endDate) {
      const start = new Date(dateobject.startDate);
      const end = new Date(dateobject.endDate);
      let timeDifference = end.getTime() - start.getTime();

      setDays(timeDifference / (1000 * 3600 * 24) + 1);
      setisNewDate(true);
    }
  };

  const submitHandler = (event) => {
    setisNewDate(false);
    event.preventDefault();

    const order = {
      startDate: dates.startDate,
      endDate: dates.endDate,
      days,
      mealtypes: {
        vegetarian: event.target[2].value > 0,
        meat: event.target[3].value > 0,
        fish: event.target[4].value > 0,
        vegRange: event.target[2].value,
        meatRange: event.target[3].value,
        fishRange: event.target[4].value,
      },
      mealsExtra: {
        priceRange: event.target[5].value,
        childFriendly: event.target[6].value,
        timeConsuming: event.target[7].value,
      },
    };

    props.submitHandler(order);
  };

  return (
    <section className={styles.form}>
      {!props.small && (
        <p
          style={{
            textAlign: "center",
            color: "red",
            border: "1px solid black",
            width: "50%",
            margin: "1rem auto",
            padding: "5px",
          }}
        >
          Første alfaversjon. Se <Link to="/om-oss">Om oss</Link> for mere
          informasjon.
        </p>
      )}
      <div style={{ position: "relative" }}>
        {!props.small && (
          <p className="helperIcon" onClick={modalClicker}>
            ?
          </p>
        )}
        {helperShow && (
          <Modal backgroundClick={modalClicker}>
            <h2>Slik gjør du det</h2>
            <ul>
              <li>1. Velg først start- og sluttdato</li>
              <li>2. Velg antall dager på de forskjellige rettene</li>
              <li>3. Sorter etter hvilke egenskaper rettene skal ha</li>
              <li>4. Trykk på "Lag meny!"</li>
            </ul>
          </Modal>
        )}
      </div>
      <form onSubmit={submitHandler}>
        <DatePicker
          dateHandler={dateHandler}
          startDate={props.startDate}
          endDate={props.endDate}
        />
        <MealCategories
          isNewDate={isNewDate}
          days={days}
          vegDays={props.vegDays}
          meatDays={props.meatDays}
          fishDays={props.fishDays}
          endDateSet={dates.endDate}
        />
        <MealsExtra
          timeConsuming={props.timeConsuming}
          childFriendly={props.childFriendly}
          priceRange={props.priceRange}
        />
        {dates.endDate && (
          <button type="submit">
            {props.small === true ? "Oppdater meny!" : "Lag meny!"}
          </button>
        )}
        {!dates.endDate && (
          <button type="submit" disabled>
            Lag meny!
          </button>
        )}
      </form>
    </section>
  );
};

export default Form;
