// {
//   meal: "",
//   price: ,
//   childFriendly: ,
//   timeConsuming: ,
//   id: ,
//   type: "",
// },

export const vegetarianMeals = [
  {
    meal: "Pastasalat",
    price: 25,
    childFriendly: 75,
    timeConsuming: 15,
    id: 1001,
    type: "vegetarian",
  },
  {
    meal: "Nøttesteik",
    price: 70,
    childFriendly: 30,
    timeConsuming: 80,
    id: 1002,
    type: "vegetarian",
  },
  {
    meal: "Fullkornslasagne",
    price: 40,
    childFriendly: 80,
    timeConsuming: 60,
    id: 1003,
    type: "vegetarian",
  },
  {
    meal: "Vegetarpølser",
    price: 20,
    childFriendly: 85,
    timeConsuming: 5,
    id: 1004,
    type: "vegetarian",
  },
  {
    meal: "Halloumi-burger",
    price: 45,
    childFriendly: 50,
    timeConsuming: 20,
    id: 1005,
    type: "vegetarian",
  },
  {
    meal: "Chili sin carne",
    price: 25,
    childFriendly: 60,
    timeConsuming: 35,
    id: 1006,
    type: "vegetarian",
  },
  {
    meal: "Blomkålsuppe",
    price: 15,
    childFriendly: 55,
    timeConsuming: 10,
    id: 1007,
    type: "vegetarian",
  },
  {
    meal: "Grønnsakssuppe",
    price: 25,
    childFriendly: 85,
    timeConsuming: 15,
    id: 1008,
    type: "vegetarian",
  },
  {
    meal: "Pannekaker",
    price: 15,
    childFriendly: 100,
    timeConsuming: 60,
    id: 1009,
    type: "vegetarian",
  },
  {
    meal: "Vegetarpizza",
    price: 30,
    childFriendly: 90,
    timeConsuming: 30,
    id: 1010,
    type: "vegetarian",
  },
  {
    meal: "Spinatpai",
    price: 45,
    childFriendly: 60,
    timeConsuming: 70,
    id: 1011,
    type: "vegetarian",
  },
  {
    meal: "Falafel i pita m/hummus",
    price: 35,
    childFriendly: 55,
    timeConsuming: 40,
    id: 1012,
    type: "vegetarian",
  },
  {
    meal: "Pastagrateng m/brokkoli",
    price: 30,
    childFriendly: 90,
    timeConsuming: 35,
    id: 1013,
    type: "vegetarian",
  },
  {
    meal: "Tomatsuppe m/egg og makaroni",
    price: 10,
    childFriendly: 80,
    timeConsuming: 15,
    id: 1014,
    type: "vegetarian",
  },
  {
    meal: "Linsesuppe",
    price: 35,
    childFriendly: 35,
    timeConsuming: 30,
    id: 1015,
    type: "vegetarian",
  },
  {
    meal: "Tikka masala /m blomkål",
    price: 55,
    childFriendly: 50,
    timeConsuming: 60,
    id: 1016,
    type: "vegetarian",
  },
  {
    meal: "Vegetarcurry med blomkål",
    price: 40,
    childFriendly: 35,
    timeConsuming: 40,
    id: 1017,
    type: "vegetarian",
  },
  {
    meal: "Spaghetti med vegetarboller",
    price: 25,
    childFriendly: 80,
    timeConsuming: 15,
    id: 1018,
    type: "vegetarian",
  },
  {
    meal: "Vegetarwok",
    price: 40,
    childFriendly: 40,
    timeConsuming: 40,
    id: 1019,
    type: "vegetarian",
  },
  {
    meal: "Vegetarnachos",
    price: 50,
    childFriendly: 50,
    timeConsuming: 50,
    id: 1020,
    type: "vegetarian",
  },
  {
    meal: "Kikertburger",
    price: 40,
    childFriendly: 35,
    timeConsuming: 50,
    id: 1021,
    type: "vegetarian",
  },
];

export const meatMeals = [
  {
    meal: "Pølser",
    price: 15,
    childFriendly: 100,
    timeConsuming: 5,
    id: 2004,
    type: "meat",
  },

  {
    meal: "Hamburger",
    price: 40,
    childFriendly: 85,
    timeConsuming: 10,
    id: 2005,
    type: "meat",
  },
  {
    meal: "Elgstek i brunsaus",
    price: 80,
    childFriendly: 40,
    timeConsuming: 80,
    id: 2006,
    type: "meat",
  },

  {
    meal: "Biff m/ bakt potet",
    price: 70,
    childFriendly: 40,
    timeConsuming: 65,
    id: 2007,
    type: "meat",
  },
  {
    meal: "Taco",
    price: 40,
    childFriendly: 80,
    timeConsuming: 25,
    id: 2008,
    type: "meat",
  },
  {
    meal: "Kjøttkaker i brunsaus",
    price: 40,
    childFriendly: 75,
    timeConsuming: 40,
    id: 2009,
    type: "meat",
  },
  {
    meal: "Pepperonipizza",
    price: 35,
    childFriendly: 90,
    timeConsuming: 25,
    id: 2010,
    type: "meat",
  },
  {
    meal: "Pasta bolognese",
    price: 35,
    childFriendly: 85,
    timeConsuming: 20,
    id: 2011,
    type: "meat",
  },
  {
    meal: "Pasta carbonara",
    price: 35,
    childFriendly: 85,
    timeConsuming: 20,
    id: 2012,
    type: "meat",
  },
  {
    meal: "Kyllingfilet m/ris og saus",
    price: 50,
    childFriendly: 65,
    timeConsuming: 35,
    id: 2013,
    type: "meat",
  },
  {
    meal: "Koteletter m/ bernaise-saus",
    price: 60,
    childFriendly: 65,
    timeConsuming: 40,
    id: 2014,
    type: "meat",
  },
  {
    meal: "Løvbiff m/ pommes frites",
    price: 55,
    childFriendly: 80,
    timeConsuming: 20,
    id: 2015,
    type: "meat",
  },
  {
    meal: "Ribbe",
    price: 60,
    childFriendly: 60,
    timeConsuming: 90,
    id: 2016,
    type: "meat",
  },
  {
    meal: "Kyllingvinger m/ loff,ris og salat",
    price: 40,
    childFriendly: 70,
    timeConsuming: 40,
    id: 2017,
    type: "meat",
  },
  {
    meal: "Nachos",
    price: 40,
    childFriendly: 55,
    timeConsuming: 45,
    id: 2018,
    type: "meat",
  },
  {
    meal: "Svinekjøttwok m/ grønnsaker og nudler",
    price: 40,
    childFriendly: 60,
    timeConsuming: 40,
    id: 2019,
    type: "meat",
  },
  {
    meal: "Pølsegrateng",
    price: 20,
    childFriendly: 100,
    timeConsuming: 30,
    id: 2020,
    type: "meat",
  },
  {
    meal: "Pulled pork m/ coleslaw",
    price: 80,
    childFriendly: 50,
    timeConsuming: 100,
    id: 2021,
    type: "meat",
  },
  {
    meal: "Skinkesteik m/ surkål",
    price: 65,
    childFriendly: 50,
    timeConsuming: 75,
    id: 2022,
    type: "meat",
  },
  {
    meal: "Hjemmelaget kjøttpudding",
    price: 65,
    childFriendly: 80,
    timeConsuming: 80,
    id: 2023,
    type: "meat",
  },
  {
    meal: "Spaghetti m/ bacon",
    price: 15,
    childFriendly: 90,
    timeConsuming: 10,
    id: 2024,
    type: "meat",
  },
];

export const fishMeals = [
  {
    meal: "Kokt torsk",
    price: 60,
    childFriendly: 60,
    timeConsuming: 10,
    id: 3001,
    type: "fish",
  },
  {
    meal: "Pannestekt ørret m/agurksalat",
    price: 70,
    childFriendly: 25,
    timeConsuming: 20,
    id: 3002,
    type: "fish",
  },
  {
    meal: "Sushi",
    price: 80,
    childFriendly: 15,
    timeConsuming: 80,
    id: 3003,
    type: "fish",
  },
  {
    meal: "Fiskegrateng",
    price: 35,
    childFriendly: 75,
    timeConsuming: 65,
    id: 3004,
    type: "fish",
  },
  {
    meal: "Laksewraps",
    price: 65,
    childFriendly: 55,
    timeConsuming: 25,
    id: 3005,
    type: "fish",
  },
  {
    meal: "Fiskeboller i hvitsaus",
    price: 35,
    childFriendly: 80,
    timeConsuming: 40,
    id: 3006,
    type: "fish",
  },
  {
    meal: "Pastasalat m/ laks",
    price: 50,
    childFriendly: 60,
    timeConsuming: 20,
    id: 3007,
    type: "fish",
  },
  {
    meal: "Lakseburger m/ coleslaw",
    price: 45,
    childFriendly: 55,
    timeConsuming: 40,
    id: 3008,
    type: "fish",
  },
  {
    meal: "Kremet fiskesuppe",
    price: 40,
    childFriendly: 40,
    timeConsuming: 40,
    id: 3009,
    type: "fish",
  },
  {
    meal: "Grillet makrell m/ agurksalat",
    price: 60,
    childFriendly: 35,
    timeConsuming: 50,
    id: 3010,
    type: "fish",
  },
  {
    meal: "Fiskekaker m/ rotgrønnsaker, poteter",
    price: 35,
    childFriendly: 50,
    timeConsuming: 20,
    id: 3011,
    type: "fish",
  },
  {
    meal: "Fiskepinner",
    price: 10,
    childFriendly: 90,
    timeConsuming: 20,
    id: 3012,
    type: "fish",
  },
  {
    meal: "Ovnsbakt laks m/ poteter og grønnsaker",
    price: 60,
    childFriendly: 60,
    timeConsuming: 35,
    id: 3013,
    type: "fish",
  },
  {
    meal: "Rekesandwich",
    price: 80,
    childFriendly: 15,
    timeConsuming: 50,
    id: 3014,
    type: "fish",
  },
  {
    meal: "Tagliatelle med scampi",
    price: 55,
    childFriendly: 30,
    timeConsuming: 30,
    id: 3015,
    type: "fish",
  },
  {
    meal: "Bakt potet med krabbe",
    price: 65,
    childFriendly: 20,
    timeConsuming: 60,
    id: 3016,
    type: "fish",
  },
];
// {
// meal:
// price:
// childFriendly:
// timeConsuming:
// },
