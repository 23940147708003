import Menu from "../components/Menu/Menu";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../hooks/use-http";
import { getSpecificMenu } from "../backend/Firebase";
import styles from "./DinnerGenerator.module.css";

const SavedMenu = () => {
  const params = useParams();
  console.log(params.menuID);

  const { sendRequest, status, data: ordered } = useHttp(getSpecificMenu);
  let counter = 0;
  useEffect(() => {
    sendRequest(params.menuID);
  }, [sendRequest, params.menuID]);

  const dayHandler = (noCount) => {
    let menuDay = new Date(ordered.startDate);
    menuDay.setDate(menuDay.getDate() + counter);
    if (!noCount) {
      counter++;
    }
    return menuDay;
  };

  let menu;

  if (status === "pending") {
    menu = <div>Loading...</div>;
  }

  if (status === "completed" && !ordered) {
    menu = <p>No comments were added yet!</p>;
  }

  if (status === "completed" && ordered) {
    console.log("completed");

    menu = (
      <div className={styles.menu}>
        <div id="menyen">
          <div>
            <p>Meny for {ordered.days} dager</p>
          </div>
          <ul className={styles.mealsList}>
            {ordered.meals.map((meal) => {
              return (
                <Menu
                  // randomMealListChanger={randomMealListChanger}
                  savedMenu={true}
                  type={meal.type}
                  counter={counter}
                  dateCounter={dayHandler()}
                  count={counter}
                  meal={meal.meal}
                  childFriendly={meal.childFriendly}
                  price={meal.price}
                  key={meal.id * Date.now() * Math.random()}
                  timeConsuming={meal.timeConsuming}
                  startDate={meal.startDate}
                  // longList={longListMeals}
                  // weekCounter={weekCountHandler}
                />
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  // menu = (
  //   <p>FYUKC</p>
  // <Menu
  //   // randomMealListChanger={randomMealListChanger}
  //   type={ordered.type}
  //   counter={counter}
  //   dateCounter={dayHandler()}
  //   count={counter}
  //   meal={ordered.meal}
  //   childFriendly={ordered.childFriendly}
  //   price={ordered.price}
  //   key={ordered.id * Date.now() * Math.random()}
  //   timeConsuming={ordered.timeConsuming}
  //   startDate={ordered.startDate}
  //   // longList={longListMeals}
  //   // weekCounter={weekCountHandler}
  // />
  // );

  return <section className={styles.DinnerGenerator}>{menu}</section>;
};

export default SavedMenu;
