import { useState } from "react";
import styles from "./DinnerGenerator.module.css";
import Form from "../components/Form/Form";
import { useNavigate } from "react-router-dom";
import { vegetarianMeals, meatMeals, fishMeals } from "../Meals/Meals.js";
import Menu from "../components/Menu/Menu";
import React from "react";
import { addMenu } from "../backend/Firebase";
import Modal from "../ui/Modal";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";

function DinnerGenerator() {
  const [menuMade, setMenuMade] = useState(false);
  const [menuLoading, setMenuLoading] = useState(false);

  const [meals, setMeals] = useState([]);
  const [ordered, setOrdered] = useState();
  const [longListMeals, setLongListMeals] = useState([]);
  const [vegDaysState, setVegDaysState] = useState();
  const [fishDaysState, setFishDaysState] = useState();
  const [meatDaysState, setMeatDaysState] = useState();
  const [helperShow, setHelperShow] = useState(false);
  let counter = 0;

  const navigate = useNavigate();

  const saveMenuHandler = () => {
    const orderedMenu = { ...ordered, meals: [...meals] };

    addMenu(orderedMenu, navigate);
  };
  const randomMealListChanger = (meal, index) => {
    meals[index] = meal;

    setMeals(meals);
  };

  const submitHandler = (order) => {
    setMeatDaysState(order.mealtypes.meatRange);
    setFishDaysState(order.mealtypes.fishRange);
    setVegDaysState(order.mealtypes.vegRange);
    setMenuMade(true);
    setMenuLoading(true);

    sortMenu(order);
    setOrdered(order);
  };

  const modalClicker = () => {
    setHelperShow((prevState) => {
      return !prevState;
    });
  };

  const mealDifference = (mealRangeChoosed, mealRange) => {
    return Math.abs(mealRangeChoosed - mealRange) <= 50;
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  };

  const sortMenu = async (order) => {
    let mealsChoosed = [];
    let meatMealsChoosed = [];
    let fishMealsChoosed = [];
    let vegetarianMealsChoosed = [];
    let meatDays = 0;
    let vegDays = 0;
    let fishDays = 0;
    let mealsLongList = [];

    // MEAT
    if (order.mealtypes.meat === true) {
      meatDays = order.mealtypes.meatRange;

      for (let i = 0; i < meatMeals.length; i++) {
        if (
          mealDifference(
            order.mealsExtra.childFriendly,
            meatMeals[i].childFriendly
          ) &&
          mealDifference(order.mealsExtra.priceRange, meatMeals[i].price) &&
          mealDifference(
            order.mealsExtra.timeConsuming,
            meatMeals[i].timeConsuming
          )
        ) {
          mealsLongList.push(meatMeals[i]);
          meatMealsChoosed.push(meatMeals[i]);
        }
      }

      shuffleArray(meatMealsChoosed);

      while (
        meatDays > meatMealsChoosed.length &&
        meatMealsChoosed.length > 0
      ) {
        meatMealsChoosed.forEach((meal) => meatMealsChoosed.push(meal));
      }
      if (meatMealsChoosed.length > 0) {
        for (let i = 0; i < meatDays; i++) {
          mealsChoosed.push(meatMealsChoosed[i]);
        }
      }
    }

    // FISH

    if (order.mealtypes.fish === true) {
      fishDays = order.mealtypes.fishRange;
      setFishDaysState(fishDays);
      for (let i = 0; i < fishMeals.length; i++) {
        if (
          mealDifference(
            order.mealsExtra.childFriendly,
            fishMeals[i].childFriendly
          ) &&
          mealDifference(order.mealsExtra.priceRange, fishMeals[i].price) &&
          mealDifference(
            order.mealsExtra.timeConsuming,
            fishMeals[i].timeConsuming
          )
        ) {
          mealsLongList.push(fishMeals[i]);
          fishMealsChoosed.push(fishMeals[i]);
        }
      }

      shuffleArray(fishMealsChoosed);
      while (
        fishDays > fishMealsChoosed.length &&
        fishMealsChoosed.length > 0
      ) {
        fishMealsChoosed.forEach((meal) => fishMealsChoosed.push(meal));
      }
      if (fishMealsChoosed.length > 0) {
        for (let i = 0; i < fishDays; i++) {
          mealsChoosed.push(fishMealsChoosed[i]);
        }
      }
    }

    //VEGETARIAN

    if (order.mealtypes.vegetarian === true) {
      vegDays = order.mealtypes.vegRange;
      setVegDaysState(vegDays);

      for (let i = 0; i < vegetarianMeals.length; i++) {
        if (
          mealDifference(
            order.mealsExtra.childFriendly,
            vegetarianMeals[i].childFriendly
          ) &&
          mealDifference(
            order.mealsExtra.priceRange,
            vegetarianMeals[i].price
          ) &&
          mealDifference(
            order.mealsExtra.timeConsuming,
            vegetarianMeals[i].timeConsuming
          )
        ) {
          mealsLongList.push(vegetarianMeals[i]);
          vegetarianMealsChoosed.push(vegetarianMeals[i]);
        }
      }
      shuffleArray(vegetarianMealsChoosed);
      while (
        vegDays > vegetarianMealsChoosed.length &&
        vegetarianMealsChoosed.length > 0
      ) {
        vegetarianMealsChoosed.forEach((meal) =>
          vegetarianMealsChoosed.push(meal)
        );
      }
      if (vegetarianMealsChoosed.length > 0) {
        for (let i = 0; i < vegDays; i++) {
          mealsChoosed.push(vegetarianMealsChoosed[i]);
        }
      }
    }

    if (mealsChoosed.length !== order.days) {
      let random = Math.floor(Math.random() * mealsChoosed.length);

      let isLarger = meatDays + vegDays + fishDays > order.days;
      let diff = meatDays + vegDays + fishDays - order.days;
      if (isLarger) {
        mealsChoosed.splice(random, diff);
      } else {
        console.log(mealsChoosed[random]);

        mealsChoosed.push(mealsChoosed[random]);
      }
    }

    shuffleArray(mealsLongList);
    setLongListMeals(mealsLongList);
    shuffleArray(mealsChoosed);

    setMeals(mealsChoosed);
    setMenuLoading(false);
  };
  let weekCount = 0;

  const weekCountHandler = () => {
    weekCount++;
    return weekCount;
  };

  const dayHandler = (noCount) => {
    let menuDay = new Date(ordered.startDate);
    menuDay.setDate(menuDay.getDate() + counter);
    if (!noCount) {
      counter++;
    }
    return menuDay;
  };

  //JSX

  if (!menuMade) {
    return <Form submitHandler={submitHandler} />;
  }
  if (menuMade && menuLoading) {
    return (
      <section className={styles.DinnerGenerator}>
        <div className={styles.containerloader}>
          <div>
            <p>Loading...</p>
          </div>
        </div>
      </section>
    );
  }
  if (menuMade && !menuLoading) {
    return (
      <section className={styles.DinnerGenerator}>
        <div className={styles.smallForm}>
          <Form
            submitHandler={submitHandler}
            small={true}
            startDate={ordered.startDate}
            endDate={ordered.endDate}
            vegDays={vegDaysState}
            meatDays={meatDaysState}
            fishDays={fishDaysState}
            daysFromGenerator={ordered.days}
            timeConsuming={ordered.mealsExtra.timeConsuming}
            childFriendly={ordered.mealsExtra.childFriendly}
            priceRange={ordered.mealsExtra.priceRange}
          />
        </div>
        <div className={styles.menu}>
          <div id="menyen">
            <div>
              <p>Meny for {ordered.days} dager</p>
              <div style={{ position: "relative" }}>
                <p className="helperIcon" onClick={modalClicker}>
                  ?
                </p>

                {helperShow && (
                  <Modal backgroundClick={modalClicker}>
                    <h2>Her ser du din genererte meny.</h2>
                    <ul>
                      <li>
                        Likte du ikke hva du fikk? I menyen til venstre kan du
                        velge å lage alt helt på nytt
                      </li>
                      <li>
                        <AutorenewIcon /> : Bytter rett til en tilfeldig rett
                        innenfor valgparameterne dine.
                      </li>
                      <li>
                        <EditIcon /> : Gir deg mulighet til å skrive inn hvilken
                        rett du vil ha.
                      </li>
                      <li>
                        Trykk på Lagre Meny om du vil lagre menyen. Du finner
                        den igjen på siden "Innlogging"
                      </li>
                    </ul>
                  </Modal>
                )}
              </div>
              <button onClick={saveMenuHandler}>Lagre meny</button>
              <p>(Databasetilgang er til tider avslått)</p>
            </div>
            <ul className={styles.mealsList}>
              {meals.map((meal) => {
                return (
                  <div key={12 * Date.now() * Math.random()}>
                    <Menu
                      randomMealListChanger={randomMealListChanger}
                      type={meal.type}
                      counter={counter}
                      dateCounter={dayHandler()}
                      count={counter}
                      meal={meal.meal}
                      childFriendly={meal.childFriendly}
                      price={meal.price}
                      key={meal.id * Date.now() * Math.random()}
                      timeConsuming={meal.timeConsuming}
                      startDate={ordered.startDate}
                      longList={longListMeals}
                      weekCounter={weekCountHandler}
                    />
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default DinnerGenerator;
