import { useEffect } from "react";
import { getAllMenus } from "../backend/Firebase";
import useHttp from "../hooks/use-http";
import { Link } from "react-router-dom";

const LogIn = () => {
  const { sendRequest, status, data: loadedMenus } = useHttp(getAllMenus);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  let menus;

  if (status === "pending") {
    menus = <div>Loading...</div>;
  }

  if (status === "completed" && (!loadedMenus || loadedMenus.length === 0)) {
    menus = <p>Databasetilgang avslått!</p>;
  }

  if (status === "completed" && loadedMenus && loadedMenus.length > 0) {
    console.log(loadedMenus);
    menus = (
      <ul>
        {" "}
        {loadedMenus.map((menus) => {
          return (
            <li>
              <Link
                to={{
                  pathname: `/menyer/${menus.id}`,
                  state: { fromDashboard: true },
                }}
              >
                Meny: {menus.startDate} - {menus.endDate}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }
  return (
    <div className="centered">
      <h2>Innloggingsside/Min side</h2>
      <p>
        Backendløsning for lagring av menyer. Vil kreve innlogging. Databasen
        ligger helt åpen for lesing/skriving nå, derfor er tilgang avslått
        mesteparten av tiden. Benytter FireBase. Sikkerhetsregler,
        inn/ut-logging, oppdatering og sletting av menyer gjenstår. Spesifikke
        menyer skal være delbare til alle.
      </p>
      <h2>Mine lagrede menyer:</h2>
      {menus}
    </div>
  );
};

export default LogIn;
