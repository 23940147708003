const MealsSlider = (props) => {
  return (
    <div>
      <p style={{ fontWeight: "bold" }}>{props.header}</p>
      {props.start}
      <input
        onChange={props.onChange}
        type="range"
        name={props.name}
        id={props.id}
        defaultValue={props.value}
        min="1"
        max="100"
      />
      {props.slutt}
    </div>
  );
};

export default MealsSlider;
