import MealsSlider from "./MealsSlider";

const MealsExtra = (props) => {
  return (
    <div>
      <MealsSlider
        value={props.priceRange}
        header="Pris"
        start="Billig"
        slutt="Dyr"
        name="price"
        id="price"
      ></MealsSlider>
      <MealsSlider
        value={props.childFriendly}
        header="Barnevennlig"
        start="Lite"
        slutt="Mætt"
        name="childFriendly"
        id="childFriendly"
      ></MealsSlider>
      <MealsSlider
        value={props.timeConsuming}
        header="Kokketid"
        start="Kort"
        slutt="Lang"
        name="time"
        id="time"
      ></MealsSlider>
    </div>
  );
};

export default MealsExtra;
