import { useState, useRef, useEffect } from "react";

const DatePicker = (props) => {
  const date = () => {
    let today = new Date();
    const offset = today.getTimezoneOffset();
    today = new Date(today.getTime() - offset * 60 * 1000);
    return today.toISOString().split("T")[0];
  };

  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(null);

  const startDateRef = useRef();
  const endDateRef = useRef();

  const dateChecker = () => {
    if (props.startDate) {
      setStartDate(props.startDate);
    }
    if (props.endDate) {
      setEndDate(props.endDate);
    }
  };

  useEffect(() => {
    dateChecker();
  });

  const startDateHandler = (event) => {
    setStartDate(event.target.value);
    dateSender();
  };

  const dateSender = () => {
    props.dateHandler({
      startDate: startDateRef.current.value,
      endDate: endDateRef.current.value,
    });
  };

  return (
    <div>
      <label htmlFor="start">Start:</label>
      <input
        ref={startDateRef}
        type="date"
        id="start"
        defaultValue={startDate}
        onChange={startDateHandler}
      ></input>
      <label htmlFor="end">Slutt:</label>
      <input
        type="date"
        name="end"
        id="end"
        defaultValue={endDate}
        onChange={dateSender}
        min={startDate}
        ref={endDateRef}
      ></input>
    </div>
  );
};

export default DatePicker;
