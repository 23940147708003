const Recipes = () => {
  return (
    <div className="centered">
      <h2>Oppskrifter</h2>
      <p>
        En mulighet å opprette oppskrifter tilknytta de rettene. Lenkes fra
        menyrettene og direkte til oppskrift. Man kan også gå via denne siden
        for å browse i oppskrifter. Evt. kan dette være en side for å lenke til
        gode matressurser på nett.
      </p>
      <p>Høyst usikkert hele greia.</p>
    </div>
  );
};

export default Recipes;
