// import { getAuth, signInAnonymously } from "firebase/auth";
// import { initializeApp } from "firebase/app";
// import { getDatabase, ref, onValue } from "firebase/database";
const FIREBASE_DOMAIN = "https://react-http-26ced-default-rtdb.firebaseio.com";
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: process.env.REACT_APP_authDomain,
//   databaseURL: process.env.REACT_APP_databaseURL,
//   projectId: process.env.REACT_APP_projectId,
//   storageBucket: process.env.REACT_APP_storageBucket,
//   messagingSenderId: process.env.REACT_APP_messagingSenderId,
//   appId: process.env.REACT_APP_appId,
// };
// initializeApp(firebaseConfig);

// const auth = getAuth();
// const db = getDatabase();

// const menus = ref(db, "menu/");
// onValue(menus, (snapshot) => {
//   const data = snapshot.val();
//   console.log(data);
// });

// const app = initializeApp(firebaseConfig);

export async function addMenu(menuData, navigate) {
  const response = await fetch(`${FIREBASE_DOMAIN}/menu.json`, {
    method: "POST",
    body: JSON.stringify(menuData),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();

  if (!response.ok) {
    console.log(response);
    alert("Opplasting feilet. Kode: " + response.status);
    throw new Error(data.message || "Could not upload menu.");
  }
  alert("Meny opplastet!");
  navigate("/innlogging");

  return null;
}

export async function getAllMenus() {
  const response = await fetch(`${FIREBASE_DOMAIN}/menu.json`);

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || "Kunne ikke finne noen menyer.");
  }
  const menuItems = [];
  for (const x in data) {
    console.log(x);
  }
  //   const transformedComments = [];

  for (const key in data) {
    const commentObj = {
      id: key,
      ...data[key],
    };

    menuItems.push(commentObj);
  }

  return menuItems;
}

export async function getSpecificMenu(menuID) {
  //   console.log(`${FIREBASE_DOMAIN}/menu/${menuID}.json`);
  const response = await fetch(`${FIREBASE_DOMAIN}/menu/${menuID}.json`);

  const data = await response.json();
  console.log(data);

  if (!response.ok) {
    // alert("Kunne ikke hente noe");
    throw new Error(data.message || "Could not get Menu.");
  }

  //   const transformedComments = [];

  return data;
  //   return ["HAA", "S"];
}

// signInAnonymously(auth)
//   .then(() => {
//     console.log(auth);
//     return true;
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     alert(`${errorCode}  ${errorMessage}`);
//   });
