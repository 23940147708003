import styles from "./Main.module.css";
import { Link } from "react-router-dom";

const Main = () => {
  return (
    <section className="centered">
      <h1>Velkommen til Middagsmeny.no</h1>
      <p>Er du luta lei av å finne ut hva du skal spise til middag hver dag?</p>
      <p>
        Hva om du kan slippe å ta valget? Prøv vår banebrytende
        middagsmenygenerator!
      </p>
      <p>
        Generer retter etter dine preferanser. Menyene kan lagres og deles med
        andre
      </p>
      <Link
        to="/meny"
        className={styles.ctaLink}
        style={{ textDecoration: "none" }}
      >
        <div className={styles.cta}>LAG MIDDAGSMENY</div>
      </Link>
    </section>
  );
};

export default Main;
