import DinnerGenerator from "./pages/DinnerGenerator";
import About from "./pages/About";
import LogIn from "./pages/LogIn";
import Recipes from "./pages/Recipes";
import { Routes, Route } from "react-router-dom";
import Layout from "./ui/Layout";
import React from "react";
import SavedMenu from "./pages/SavedMenu";
import Main from "./pages/Main";

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/meny" element={<DinnerGenerator />} />
        <Route path="/om-oss" element={<About />} />
        <Route path="/innlogging" element={<LogIn />} />
        <Route path="/menyer/:menuID" element={<SavedMenu />} />
        <Route path="/oppskrifter" element={<Recipes />} />
      </Routes>
    </Layout>
  );
};

export default App;
