const About = () => {
  return (
    <div className="centered">
      <h2>Velkommen til alfaversjonen av Middagsmeny.no</h2>
      <p>
        Siden ligger hovedsaklig ute for å høste tilbakemeldinger på
        menygenereringen. En slags grovversjon av mange av funksjonene som siden
        skal ha, er på plass. Men det gjenster mye arbeid og finpuss.
      </p>
      <p>
        Utslag fra valg av pris, kokketid og barnevennlighet er programmert
        ganske upresist nå. Derfor vil du få treff på retter som skiller seg
        ganske mye fra hva du har valgt. Dette er på grunn av det lave antallet
        retter i basen.{" "}
      </p>
      <p>
        Sidens layout/utseende er ikke påbegynt. Det meste er standard
        HTML-utseende. Optimalisering for flere enheter, som mobil og nettbrett
        er ikke påbegynt.
      </p>

      <p>Nettsiden utvikles med Javascript i React.</p>

      <p>Kristian Slaatsveen - 16.01.2022</p>
    </div>
  );
};

export default About;
