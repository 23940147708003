import { NavLink, Link } from "react-router-dom";

import classes from "./NavBar.module.css";

const NavBar = () => {
  return (
    <header className={classes.header}>
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className={classes.logo}>Middagsmeny.no</div>
      </Link>
      <nav className={classes.nav}>
        <ul>
          <li>
            <NavLink
              to="/meny"
              className={(navData) => (navData.isActive ? classes.active : "")}
            >
              Lag meny
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/om-oss"
              className={(navData) => (navData.isActive ? classes.active : "")}
            >
              Om oss
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/innlogging"
              className={(navData) => (navData.isActive ? classes.active : "")}
            >
              Innlogging
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/oppskrifter"
              className={(navData) => (navData.isActive ? classes.active : "")}
            >
              Oppskrifter
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default NavBar;
